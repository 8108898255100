import React from "react";
import Header from "../Header/Header";
import NotificationList from "../NotificationList/NotificationList";

export default function Homepage() {
  return (
    <>
      <Header />
      <NotificationList />
    </>
  );
}
